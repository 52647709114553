import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62647e1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "nav-content" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "nav-logo",
        src: require('@/assets/images/slogan.png')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["nav-item", { 'nav-activate': _ctx.activateNav === item.path }]),
            key: item.value,
            onClick: ($event: any) => (_ctx.onNavClick(item.value))
          }, _toDisplayString(item.name), 11, _hoisted_5))
        }), 128)),
        _createElementVNode("div", {
          class: "nav-item nav-item-btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.consult && _ctx.consult(...args)))
        }, "立即咨询")
      ])
    ])
  ]))
}