//例子
// type EventTypes = {
//     CONSULT_POPUP: boolean
// }
// const test = new $Event<EventTypes>()
// test.on('CONSULT_POPUP', (event: boolean) => {
//     console.log(event)
// })
// test.emit('CONSULT_POPUP', false)
export default class $Event<Events> {
    private eventPool: Map<keyof Events, Array<Function>> = new Map()
    on<Key extends keyof Events>(type: Key, cb: (event: Events[Key]) => void) {
        let events = this.eventPool.get(type)
        if (events) {
            events.push(cb)
        } else {
            events = [cb]
        }
        this.eventPool.set(type, events)
    }
    emit<Key extends keyof Events>(type: Key, arg?: Events[Key], ...rest: any) {
        const events = this.eventPool.get(type)
        if (events) {
            events.forEach((event: Function) => event(arg, ...rest));
        }
    }
    off<Key extends keyof Events>(type: Key, cb?: Function) {
        const events = this.eventPool.get(type)
        if (events) {
            if (cb) {
                // 传了原事件则删除原事件
                events.splice(events.findIndex((e: Function) => e === cb), 1)
            } else {
                // 否则删除所有事件
                this.eventPool.delete(type)
            }
        }
    }
}