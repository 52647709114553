
import { defineComponent, ref, reactive, onDeactivated } from "vue";
import SwiperClass, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";
import $bus from "@/utils/bus";
import { isElemVisible } from "@/utils/util";
import { onBannerLoadAndActivated } from "./util";
export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        // 核心能力介绍
        interface ItabItem {
            swiper?: SwiperClass;
            [propName: string]: any;
        }
        const introduceTabs: Array<ItabItem> = reactive([
            { name: "获客服务", value: 0, url: "get" },
            { name: "运营支持系统", value: 1, url: "operation" },
            { name: "小程序/APP", value: 2, url: "app" },
        ]);
        const activateIntroduce = ref(0);
        function onIntroduceTabs(value: number) {
            activateIntroduce.value = value;
            const swiper = introduceTabs[value].swiper;
            if (swiper) {
                swiper.slideTo(1);
                swiper.autoplay.start();
            }
        }
        const onSwiper = (swiper: SwiperClass, tabValue: number) => {
            const tab = introduceTabs[tabValue];
            tab.swiper = swiper;
            if (tab.value === 0) {
                tab.swiper.autoplay.start();
            }
        };
        let timer: number | undefined;
        function onSlideChangeTransitionEnd(
            swiper: SwiperClass,
            tabValue: number
        ) {
            if (timer != undefined) {
                clearTimer();
            }
            if (swiper.realIndex === 3) {
                swiper.autoplay.stop();
                // 最后一个
                timer = setTimeout(() => {
                    const nextTab = tabValue + 1;
                    onIntroduceTabs(
                        nextTab < introduceTabs.length ? nextTab : 0
                    );
                    timer = undefined;
                }, 3000);
            }
        }
        function clearTimer() {
            clearTimeout(<number>timer);
            timer = undefined;
        }
        function showPopup() {
            $bus.emit("CONSULT_POPUP", true);
        }

        let fadeInElements: Array<HTMLElement>; //需要渐入数组
        function handleScroll() {
            fadeInElements = fadeInElements.filter((ele) => {
                if (isElemVisible(ele)) {
                    ele.classList.add("fade-in-end");
                    return false; // 只让它运行一次
                } else {
                    return true;
                }
            });
            if (!fadeInElements.length) {
                document.removeEventListener("scroll", handleScroll);
            }
        }
        onBannerLoadAndActivated(() => {
            fadeInElements = (
                Array.from(
                    document.getElementsByClassName("fade-in")
                ) as Array<HTMLElement>
            ).filter((el) => !el.className.includes("fade-in-end"));
            document.addEventListener("scroll", handleScroll);
            handleScroll();
        });
        onDeactivated(() => {
            document.removeEventListener("scroll", handleScroll);
        });
        return {
            introduceTabs,
            activateIntroduce,
            onIntroduceTabs,
            swiperModules: [Navigation, Pagination, Autoplay],
            onSwiper,
            onSlideChangeTransitionEnd,
            showPopup,
        };
    },
});
