
import { defineComponent, onMounted, reactive } from "vue";
export default defineComponent({
    setup() {
        let map: any;
        const groups = reactive([
            {
                latLng: [22.567386, 113.866737],
                title: "总公司",
                addr: "总部：深圳市宝安区华源科技创新园A540",
                icon: "headquarters",
                select: false,
            },
            {
                latLng: [30.576464, 104.061523],
                title: "分公司",
                addr: "分公司：成都分公司/成都市高新区锦城大道666号5楼503",
                icon: "branch",
                select: false,
            },
        ]);
        function initMap() {
            //定义地图中心点坐标
            const center = new TMap.LatLng(29.08481, 110.514029);
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            map = new TMap.Map(document.getElementById("mapContainer"), {
                center: center, //设置地图中心点坐标
                zoom: 4, //设置地图缩放级别
                // pitch: 40.5,  //设置俯仰角
                // rotation: 1    //设置地图旋转角度
            });

            groups.forEach((item, index) => {
                (<any>window).onClickMapWindow = onClick;
                new TMap.InfoWindow({
                    map: map,
                    position: new TMap.LatLng(...item.latLng), //设置信息框位置
                    content: `<div onclick="onClickMapWindow(${index})" style='cursor: pointer;'><svg t="1639821144675" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2882" width="32" height="32"><path d="M528 32C325.056 32 160 196.8 160 399.36c0 75.2 22.656 147.584 65.024 208.48 2.112 3.648 4.256 7.168 6.784 10.592l268.608 353.472c7.296 8.096 17.088 12.576 27.584 12.576 10.368 0 20.224-4.512 28.768-14.08l267.36-352c2.624-3.52 4.896-7.36 6.112-9.6A364.864 364.864 0 0 0 896 399.36C896 196.8 730.912 32 528 32z m0 498.72a131.52 131.52 0 0 1-131.456-131.232 131.488 131.488 0 0 1 262.88 0 131.52 131.52 0 0 1-131.424 131.2z" p-id="2883" fill="#d81e06"></path></svg><div>`, //设置信息框内容
                    enableCustom: true,
                });
            });
            // map.on("idle", function () {
            //     console.log(map.getZoom());
            // });
        }
        function onClick(index: any) {
            const item = groups[index];
            // item.select = true;
            switchCoordinates(item.latLng[0], item.latLng[1]);
        }
        function switchCoordinates(lat: number, lng: number) {
            map.setCenter(new TMap.LatLng(lat, lng));
            map.setZoom(18);
        }
        onMounted(initMap);
        return {
            groups,
            onClick,
        };
    },
});
