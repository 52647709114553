import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/index/index.vue'
import Home from '@/views/index/home.vue'
import Case from '@/views/index/case.vue'
import Contact from '@/views/index/contact.vue'
const routes = [
    {
        path: '/',
        component: Index,
        redirect: 'index',
        children: [
            { path: 'index', component: Home },
            { path: 'case', component: Case },
            { path: 'contact', component: Contact }
        ]
    }
]

export default createRouter({ history: createWebHistory(), routes })