
import { defineComponent, reactive, onDeactivated, onMounted } from 'vue'
import $bus from '@/utils/bus'
import { isElemVisible } from '@/utils/util'
import { onBannerLoadAndActivated } from './util'
export default defineComponent({
    setup() {
        const cases = reactive([
            { brand: '爱乐空间', shop: '成都奥克斯店', logo: '1' },
            { brand: '爱乐空间', shop: '成都金牛店', logo: '1' },
            { brand: '爱乐空间', shop: '成都春熙路店', logo: '1' },
            { brand: '佳偶天成', shop: '武汉店', logo: '2' },
            // { brand: '佳偶天成', shop: '杭州店', logo: '2' },
            { brand: '全城优恋', shop: '长春店', logo: '13' },
            { brand: '全城优恋', shop: '西安店', logo: '13' },
            { brand: '全城优恋', shop: '成都店', logo: '13' },
            { brand: '全城优恋', shop: '重庆店', logo: '13' },
            { brand: '全城优恋', shop: '南京店', logo: '13' },
            { brand: '爱渡', shop: '深圳店', logo: '3' },
            { brand: '爱渡', shop: '成爱渡成都天府广场店', logo: '3' },
            { brand: '爱渡', shop: '爱渡成都红星桥店', logo: '3' },
            { brand: '爱都', shop: '悉尼店', logo: '4' },
            { brand: '爱都', shop: '墨尔本店', logo: '4' },
            { brand: '爱都', shop: '深圳店', logo: '4' },
            { brand: '爱都', shop: '全国业务店', logo: '4' },
            { brand: '爱遇空间', shop: '赣州店', logo: '5' },
            // { brand: '爱马', shop: '东莞店', logo: '6' },
            { brand: '卿我', shop: '杭州店', logo: '7' },
            { brand: '我们约会吧', shop: '长沙店', logo: '8' },
            { brand: '二十六度空间', shop: '合肥店', logo: '9' },
            { brand: '椿橙结', shop: '佛山店', logo: '10' },
            { brand: '世纪约定', shop: '成都店', logo: '11' },
            // { brand: '时代婚恋', shop: '广州店', logo: '12' },
            { brand: '重庆金玫瑰', shop: '重庆店', logo: '14' },
        ])
        function consult() {
            $bus.emit('CONSULT_POPUP', true)
        }
        let casesElements: Array<HTMLElement>;
        let waitFadeIncases: Array<HTMLElement> = [];
        function handleScroll() {
            casesElements = casesElements.filter(el => {
                if (isElemVisible(el, -130)) {
                    waitFadeIncases.push(el)
                    setTimeout(() => {
                        el.classList.add('case-fade-in')
                        waitFadeIncases.shift()
                    }, 80 * waitFadeIncases.length)
                    return false
                } else {
                    return true
                }
            })
            if (!casesElements.length) {
                document.removeEventListener('scroll', handleScroll)
            }
        }
        onMounted(() => {
            casesElements = Array.from(document.getElementsByClassName('case-item')) as Array<HTMLElement>
        })
        onBannerLoadAndActivated(() => {
            if (casesElements.length) {
                document.addEventListener('scroll', handleScroll)
                handleScroll()
            }
        })
        onDeactivated(() => {
            document.removeEventListener('scroll', handleScroll)
        })
        return { cases, consult }
    }
})
