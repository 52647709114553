
import { defineComponent, ref, reactive, nextTick } from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import $bus from '@/utils/bus'
export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const activateNav = ref(route.path)
        const navs = reactive([
            { name: '首页', value: 0, path: '/index' },
            { name: '成功案例', value: 1, path: '/case' },
            { name: '联系我们', value: 2, path: '/contact' }
        ])
        function onNavClick(value: number) {
            router.replace({ path: navs[value].path })
        }
        onBeforeRouteUpdate(to => {
            activateNav.value = to.path
            if (to.path !== '/index') {
                nextTick(() => {
                    (document.querySelector('#pageContent') as HTMLElement).scrollIntoView({
                        behavior: 'smooth'
                    });
                })
            }
        })
        function consult() {
            $bus.emit('CONSULT_POPUP', true)
        }
        return {
            navs,
            activateNav,
            onNavClick,
            consult
        }
    }
})
